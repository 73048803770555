.in-room-main-content {
  display: flex;
  flex-direction: row;
  color: #e1e2e3;
  align-items: stretch;

  .left-spacer {
    width: 8vh;
  }

  .left-panel {
    flex-basis: 30%;
    background-color: #1c1f2d;
    color: #c9cacb;

    h2 {
      font-size: 1.2rem;
      padding: 1.52rem 1.52rem 0.76rem 1.52rem;
      color: #e4e5e6;
    }

    .organizer, .attendees, .video-url {
      display: flex;
      font-size: 0.7rem;
      padding: 0.1rem 1.52rem;
    }

    .attendees {
      span.attendee:after {
        content: ',';
      }

      span.attendee:last-of-type:after {
        content: '';
      }
    }

    .video-url a {
      text-decoration: none;
      color: #c9cacb;
    }
  }

  .center-panel {
    flex-basis: 44%;
    background-color: #222533;
    margin-left: 0.15rem;

    .meeting-title {
      background-color: #333643;
      height: 3rem;
      display: flex;
      align-items: center;

      .color-spacer {
        background-color: #d6d6d9;
        width: 0.5vw;
        height: 100%;
      }
    }

    .current-event {
      padding: 0 0.5rem;
      margin: 0 0 0 1.5vw;
    }

    .meeting-description {
      font-size: 0.6rem;
      padding: 1.14rem 1.90rem 0.57rem 1.90rem;

      p {
        white-space: pre-wrap;
        word-wrap: break-word;
        word-break: break-all;
        margin: 0;
      }
    }

    .meeting-until {
      padding: 0 0 1.90rem 1.90rem;
    }
  }

  .right-panel {
    flex-basis: 20%;
    margin-left: 0.15rem;

    .next-meeting {
      background-color: #1c1f2d;
      padding: 0.7rem;
      color: #666870;
      margin-bottom: 0.15rem;

      .meeting-title h4 {
        font-size: 0.85rem;
        margin: 0 0 0.3rem 0;
      }

      .meeting-organizer {
        font-size: 0.5rem;
      }

      .meeting-time {
        margin: 0.76rem 0 0 0;
        font-size: 0.65rem;
      }
    }
  }
}

.progress-bar {
  width: 100%;
  height: 2%;
  background-color: #c14c58;
  position: relative;
  overflow: hidden;

  .progress-dot {
    width: 2%;
    height: 100%;
    background-color: #eac3c8;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.progress-bar.ongoing {
  background-color: #c14c58;
}

.progress-bar.vacant {
  background-color: #0CC3BB;
}
