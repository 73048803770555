.center-panel .timetable {
  .header, .event-table {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: stretch;

    color: #93949a;

    .event-time, .event-info, .timetable-title, .datetime {
      flex: 1;
      flex-basis: 50%;
    }
  }

  .header {
    border-bottom: #646670 1px solid;
    padding-bottom: 0.38rem;
    margin: 0 0.76rem 0.38rem 0.76rem;
    font-size: 1.4vw;

    .datetime {
      padding-right: 1.14rem;
      text-align: right;
    }
  }

  .no-events-info {
    color: #c9c9cb;
    font-size: 1.5vw;
    padding: 1.75rem;
  }

  .next-meeting {
    background-color: #333643;
    color: #c9c9cb;
    font-size: 1.5vw;
    padding: 0.75rem;

    h4 {
      margin: 0;
      background-color: #333643;
      color: #c9c9cb;
      font-size: 1.5vw;
    }
  }

  .meeting-organizer {
    font-size: 0.9vw;
    margin: 0.18rem 0;
  }

  .meeting-time {
    font-size: 1.4vw;
    margin: 0.76rem 0 0.38rem 0;
  }

  .events {
    margin-top: 0.76rem;
  }

  .event-table {
    padding: 0 0.76rem;

    .event-time {
      align-self: flex-start;
      margin-top: 0.18rem;
      font-size: 1.5vw;
      flex-basis: 25%;
    }

    .event-time.ampm {
      flex-basis: 40%;
    }

    .event-info {
      font-size: 1.5vw;
      font-weight: bold;
      flex-basis: 75%;
      padding: 0.18rem 0.76rem;
    }

    .event-info.ampm {
      flex-basis: 60%;
    }

    .event-organizer {
      font-size: 0.9vw;
      font-weight: normal;
      margin: 0.18rem 0;
    }
  }
}
