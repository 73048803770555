.in-room-header {
	display: flex;
	color: #c5c5c7;
  font-size: 1.4vw;
  align-items: center;

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1c1f2d;
    height: 8vh;
    width: 8vh;

    img {
      width: 5vh;
      height: 5vh;
    }
  }

  .room-name {
    flex-basis: 74%;
    display: block;
    position: relative;
    left: 2%;
  }

  .room-datetime {
    display: flex;
    align-items: center;
    text-align: center;
    color: #5b5d67;
    flex-basis: 20%;
  }

  .room-date {
    text-align: center;
    flex-basis: 65%;
  }

  .room-time {
    flex-basis: 35%;
    color: #bebec0;
    font-weight: bold;
    text-align: right;
  }
}
