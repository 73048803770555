$bg-color: #101322;
$font-color-lg: #e1e2e3;

html, body {
  background-color: $bg-color;
}

#root {
  width: 100%;
  height: 100%;

  .payment-notification {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 1.5rem;
    text-align: center;
    color: $font-color-lg;

    svg {
      width: 10rem;
      margin-bottom: 1rem;
    }
  }

  .tile-wrapper {
    display: block;
    height: 100%;
    width: 100%;
    padding: 1.14rem 1.90rem;
    background-color: #eee;
    color: #000;
  }

  .splash-screen {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    color: $font-color-lg;
    padding: 1.14rem 1.90rem;
    background-color: #101322;

    > .splash-screen-inner {
      display: inline-block;
      margin: 0 auto;
      width: 90%;
      text-align: center;

      .splash-screen-content {
        width: 100%;
      }

      img {
        width: 20vw;
        max-width: 300px;
      }

      h4 {
        font-size: 1.1rem;
      }
    }
  }

}

h1.boxed {
  background-color: #222533;
  padding: 1.14rem 0 1.14rem 0;
}

.flex-parent {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
}

.flex-item {
  flex: 1;
}

.flex-area {
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  overflow: hidden;
}
