*, *:before, *:after
{
  box-sizing: border-box;
}

html {
  font-size: 28px;
}

body {
  background: #fff;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
}

html, body {
  margin: 0;
  padding: 0;

  width: 100%;
  height: 100%;
}
